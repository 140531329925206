import { AUTH_KEY_STORAGE } from '../../../shared/constants/Key';
import { getLocalData, saveLocalData, removeLocalData } from './LocalStorage';
import {
  getSessionData,
  saveSessionData,
  removeSessionData,
} from './SessionStorage';

export function saveToken(newToken, onlySession = false) {
  if (onlySession) {
    saveSessionData(AUTH_KEY_STORAGE, newToken);
  } else {
    saveLocalData(AUTH_KEY_STORAGE, newToken);
  }
}

export function getToken() {
  return getLocalData(AUTH_KEY_STORAGE) || getSessionData(AUTH_KEY_STORAGE);
}

export function removeToken() {
  removeSessionData(AUTH_KEY_STORAGE);
  removeLocalData(AUTH_KEY_STORAGE);
}
