import React from 'react';
import { Dropdown, Layout } from 'antd';
import './index.style.less';
import AppLogo from '../components/AppLogo';
// import AppLanguageSwitcher from '../../AppLanguageSwitcher';
// import AppHeaderMessages from '../../AppHeaderMessages';
import AppNotifications from '../../AppNotifications';
import PropTypes from 'prop-types';
import { FiMoreVertical } from 'react-icons/fi';
import { AiOutlineMenu } from 'react-icons/ai';
import UserInfo from '../components/UserInfoStandard/index';
import AppQuestion from '../../AppQuestion/index';

const AppHeader = ({ isCollapsed, onToggleSidebar }) => {
  const { Header } = Layout;

  // const menuMobile = (
  //   <Menu>
  //     {/*<AppHeaderMessages />*/}
  //     <AppNotifications />
  //     <AppLanguageSwitcher />
  //   </Menu>
  // );

  const items = [
    {
      label: <AppQuestion />,
      key: 'app_header-0',
    },
    {
      label: <AppNotifications />,
      key: 'app_header-1',
    },
  ];

  return (
    <Header className='app-standard-header'>
      <a className='trigger' onClick={() => onToggleSidebar(!isCollapsed)}>
        <AiOutlineMenu />
      </a>
      <div className='app-standard-header-sectionLogo'>
        <AppLogo />
      </div>
      <div className='app-standard-header-sectionDesktop'>
        {/*<AppQuestion />*/}
        {/*<AppNotifications />*/}
        <UserInfo />
      </div>
      <div className='app-standard-header-section-mobile'>
        <UserInfo />
        <Dropdown menu={{ items }} trigger={['click']}>
          <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
            <FiMoreVertical />
          </a>
        </Dropdown>
      </div>
    </Header>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  onToggleSidebar: PropTypes.func,
  isCollapsed: PropTypes.bool,
};
