import { authRole } from '../../../shared/constants/AppEnums';

export const getUserFromAuth0 = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.sub,
      displayName: user.name,
      email: user.email,
      photoURL: user.picture,
      role: authRole.user,
    };
  return user;
};

export const getUserFromFirebase = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName ? user.displayName : 'Crema User',
      email: user.email,
      photoURL: user.photoURL ? user.photoURL : '/assets/images/avatar/A11.jpg',
      role: authRole.user,
    };
  return user;
};
export const getUserFromAWS = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : 'Crema User',
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.user,
    };
  return user;
};

export const getUserFromJwtAuth = (user) => {
  if (user) {
    return {
      id: user?.id,
      uid: user?._id,
      displayName: user?.full_name,
      permissions: [
        'department.view',
        'account.view',
        'reset_password.view',
        'label.view',
        'keywords.view',
        'source.view',
        'bot.view',
        'proxy.view',
        'sentiment.view',
      ],
      email: user?.email,
      photoURL: user?.avatar_url,
      role: authRole[user.roles[0]],
      phoneNumber: user?.phone_number,
      username: user?.username,
    };
  }
  return user;
};
