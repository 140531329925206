import { extend } from 'src/validate/plugins';

extend('exist_user_info', {
  params: ['exist_user_info'],
  validate: (value, target) => {
    const { exist_user_info } = target || {};

    if (value.length > 0) {
      try {
        if (exist_user_info) return true;
        else return false;
      } catch (e) {
        return false;
      }
    } else return true;
  },
  messageId: 'form_validate.exist_user_info',
});
