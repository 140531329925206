import React from 'react';
import notification from '../../services/db/notifications/notification';
import { List, Button, Dropdown, Badge } from 'antd';

import AppScrollbar from '../AppScrollbar';
import IntlMessages from '../../utility/IntlMessages';
import NotificationItem from './NotificationItem';
import './index.style.less';
import { AiOutlineBell } from 'react-icons/ai';

const AppNotifications = () => {
  const items = [
    {
      label: (
        <div className='header'>
          <IntlMessages id='common.notifications' />({notification.length})
        </div>
      ),
      key: 'app_notifications-1',
    },
    {
      label: (
        <div>
          <AppScrollbar className='notify-scroll-submenu'>
            <List
              className='notify-list'
              dataSource={notification}
              renderItem={(item) => {
                return <NotificationItem key={item.id} item={item} />;
              }}
            />
          </AppScrollbar>
        </div>
      ),
      key: 'app_notifications-2',
    },
    {
      label: (
        <div>
          <Button type='primary' className='notify-btn-all'>
            <IntlMessages id='common.viewAll' />
          </Button>
        </div>
      ),
      key: 'app_notifications-3',
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      className='dropdown'
      trigger={['click']}
      overlayClassName='notify-header-message'>
      <Badge count={99} overflowCount={999} offset={[8, -4]}>
        <a className='notify-link' onClick={(e) => e.preventDefault()}>
          <span className='notify-icon'>
            <AiOutlineBell />
          </span>
          <span className='notify-text'>
            <IntlMessages id='common.notifications' />
          </span>
        </a>
      </Badge>
    </Dropdown>
  );
};

export default AppNotifications;
