// import { isEmpty } from 'lodash';
import { extend } from './index';

extend('required_input_before', {
  params: ['targetInput'],
  validate: (value, target) => {
    try {
      const { targetInput } = target || {};
      if (!targetInput) return false;
      else return true;
    } catch (error) {
      return false;
    }
  },
  messageId: 'form_validate.required_input_before',
});
