import { message } from 'antd';

const antInfoMessage = (mess, duration, onClose) =>
  message.info(mess, duration, onClose);

const antErrorMessage = (mess, duration, onClose) =>
  message.error(mess, duration, onClose);

const antSuccessMessage = (mess, duration, onClose) =>
  message.success(mess, duration, onClose);

const antWarningMessage = (mess, duration, onClose) =>
  message.warning(mess, duration, onClose);

const antLoadingMessage = (mess, duration, onClose) =>
  message.loading(mess, duration, onClose);

const antMessage = {
  warning: antWarningMessage,
  success: antSuccessMessage,
  error: antErrorMessage,
  info: antInfoMessage,
  loading: antLoadingMessage,
};

export default antMessage;
