import React from 'react';
import {
  IoPeopleOutline,
  IoPricetagsOutline,
  IoKeyOutline,
  IoChatboxEllipsesOutline,
  IoServerOutline,
  IoEarthOutline,
} from 'react-icons/io5';
import { routes } from 'src/@crema/config';

export const ROUTER_NAME = {
  ACCOUNT: 'account',
  SENTIMENT: 'sentiment',
};

const routesConfig = [
  {
    id: 'hrmanagement',
    title: 'zone',
    type: 'collapse',
    messageId: 'sidebar.hrManagement',
    icon: <IoPeopleOutline />,
    children: [
      {
        id: 'department',
        title: 'Department',
        type: 'item',
        messageId: 'sidebar.departmentManagement',
        path: '/admin/department',
      },
      {
        id: 'account',
        title: 'Account',
        type: 'item',
        messageId: 'sidebar.accountManagement',
        path: '/admin/account',
      },
      {
        id: 'reset_password',
        title: 'Reset Password',
        messageId: 'sidebar.resetPasswordManagement',
        type: 'item',
        path: '/admin/reset-password',
      },
    ],
  },
  {
    id: 'label',
    title: 'Label',
    messageId: 'sidebar.labelManagement',
    type: 'item',
    icon: <IoPricetagsOutline />,
    path: '/admin/label',
  },
  {
    id: 'keywords',
    title: 'Keywords',
    messageId: 'sidebar.keywordsManagement',
    type: 'item',
    icon: <IoKeyOutline />,
    path: '/admin/keywords',
  },
  {
    id: 'source',
    title: 'Source',
    messageId: 'sidebar.sourceManagement',
    type: 'item',
    icon: <IoEarthOutline />,
    path: '/admin/source',
  },
  {
    id: 'bot',
    title: 'Bot',
    messageId: 'sidebar.botManagement',
    icon: <IoChatboxEllipsesOutline />,
    type: 'item',
    path: '/admin/bot',
  },
  {
    id: 'proxy',
    title: 'Proxy',
    messageId: 'sidebar.proxyManagement',
    icon: <IoServerOutline />,
    type: 'item',
    path: '/admin/proxy',
  },
  {
    id: ROUTER_NAME.SENTIMENT,
    title: 'Sentiment',
    messageId: 'sidebar.sentimentManagement',
    icon: <IoServerOutline />,
    type: 'item',
    path: routes.sentimentManagement,
  },
];
export default routesConfig;
