import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty } from '../../../shared/utils/Typeof';
import { getRoutePermissions } from 'src/@crema/utility/VerticalMenuUtils';
import { useAuthUser } from 'src/@crema/utility/AuthHooks';
import IntlMessages from 'src/@crema/utility/IntlMessages';
import BreadcrumbList from '../BreadcrumbList';
import SubHeaderAppTemplate from '../SubHeaderApp/SubHeaderAppTemplate';
const PageBreadcrumb = () => {
  const { pathname } = useLocation();
  const { user } = useAuthUser();
  const [breadcrumbItem, setBreadcrumbItem] = useState([]);
  const [pageTitle, setPageTitle] = useState('sidebar.home');
  const routes = getRoutePermissions(user?.permissions);
  useEffect(() => {
    let breadcrumbList = [];
    if (routes.length > 0) {
      if (pathname === routes[0].path) {
        breadcrumbList = [{ path: routes[0].path, title: routes[0].messageId }];
      } else {
        routes.forEach((route) => {
          if (route.path === pathname && route.type === 'item') {
            breadcrumbList.push({
              path: route.path,
              title: route.messageId,
            });
          }
          if (route.children) {
            route.children.forEach((child) => {
              if (child.path === pathname && child.type === 'item') {
                breadcrumbList.push(
                  {
                    path: null,
                    title: route.messageId,
                  },
                  {
                    path: child.path,
                    title: child.messageId,
                  },
                );
              }
            });
          }
        });
      }
    }

    const title = breadcrumbList[breadcrumbList.length - 1]?.title;
    setPageTitle(title);
    setBreadcrumbItem(breadcrumbList);
  }, [pathname]);

  if (isEmpty(breadcrumbItem)) {
    return null;
  }
  return (
    <SubHeaderAppTemplate
      title={<IntlMessages id={pageTitle} />}
      subTitle={
        breadcrumbItem?.length > 1 ? (
          <BreadcrumbList items={breadcrumbItem} />
        ) : null
      }
    />
  );
};

export default PageBreadcrumb;
