import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { history } from 'src/redux/store';
import {
  FETCH_ERROR,
  FETCH_SUCCESS,
} from '../../../../shared/constants/ActionTypes';
import { getToken, removeToken } from '../../Application/AuthenStorage';

import useFetch from 'src/@crema/hook/fetchData/useFetch';
import API from '../../apis/index';
const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({ children }) => {
  const [jwtAuthData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const dispatch = useDispatch();
  const { data, fetchData, isLoading } = useFetch({
    url: API.AUTH_ME,
    loadInit: false,
  });

  useEffect(() => {
    const getAuthUser = () => {
      const token = getToken();
      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      fetchData();
    };
    getAuthUser();
  }, []);

  const signInUser = (success, error) => {
    if (success) {
      setJWTAuthData({
        user: success,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch({ type: FETCH_SUCCESS });
    }
    if (error) {
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };
  useEffect(() => {
    if (!isLoading && data) {
      if (data.code === 0) {
        signInUser(data?.result, null);
      } else {
        signInUser(null, true);
      }
    }
  }, [data, isLoading]);

  const signUpUser = (success, error) => {
    if (success) {
      dispatch({ type: FETCH_SUCCESS });
    } else if (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };

  const logout = () => {
    removeUser();
  };

  const removeUser = () => {
    removeToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  history.listen(({ location }) => {
    if (location?.pathname == '/signin' && jwtAuthData.isAuthenticated) {
      removeUser();
    }
  });

  return (
    <JWTAuthContext.Provider
      value={{
        ...jwtAuthData,
      }}>
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          removeUser,
          logout,
        }}>
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
