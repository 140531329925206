import { isObject } from 'lodash';
import { isExistKey, isError } from './Typeof';

export const isErrorResponse = (response) => {
  return !(!isExistKey(response, 'code') || response.code === 0);
};

export const getResultResponse = (response) => {
  return response;
};

export const getMessageResponse = (response) => {
  if (isError(response)) {
    try {
      const responseData = response?.response || response || {};
      return responseData?.data?.result || responseData?.data?.message || {};
    } catch (e) {
      return response.message;
    }
  }

  if (response && (isErrorResponse(response) || isError(response))) {
    return (
      response?.response?.data?.message ||
      response?.response?.message ||
      response?.message ||
      'Đã có lỗi xảy ra! Vui lòng thử lại sau'
    );
  } else {
    return '';
  }
};

export const getErrorsResponse = (response) => {
  const responseData = response?.response || response || {};
  const errors =
    responseData?.data?.result || responseData?.data?.message || {};
  let errorsObject = {};
  if (isObject(errors)) {
    Object.keys(errors).forEach((error) => {
      errorsObject[error] = [errors[error]];
    });
    return errorsObject;
  }

  return errors;
};

export const getCodeResponse = (response) => {
  if (isError(response)) {
    try {
      return JSON.parse(response.message).status;
    } catch (e) {
      console.log(e);
    }
  }

  return response?.code || response?.data?.code;
};
