import { isEmpty } from 'src/shared/utils/Typeof';
import { extend } from 'src/validate/plugins';

extend('minLength', {
  params: ['minLength'],
  validate: (value, target) => {
    const { minLength } = target || {};
    try {
      if (isEmpty(value)) return true;
      return value.length >= minLength;
    } catch (e) {
      return false;
    }
  },
  messageId: 'form_validate.minLength',
});
