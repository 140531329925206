import { isEmpty } from 'src/shared/utils/Typeof';
import { extend } from 'src/validate/plugins';

extend('notEmpty', {
  params: [],
  validate: (value) => {
    if (value.length > 0) {
      try {
        return !isEmpty(value);
      } catch (e) {
        return false;
      }
    } else return true;
  },
  messageId: 'form_validate.require',
});
