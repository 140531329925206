import React from 'react';
import './index.style.less';
import PropTypes from 'prop-types';
import { useSidebarContext } from '../../../../utility/AppContextProvider/SidebarContextProvider';
import Logo from '../../../../../assets/image/Logo.png';

const AppLogo = ({ hasSidebarColor }) => {
  const { sidebarColorSet } = useSidebarContext();
  return (
    <div className='app-logo'>
      {hasSidebarColor && sidebarColorSet.mode === 'dark' ? (
        <img src={Logo} alt='alert-logo' />
      ) : (
        <img src={Logo} alt='alert-logo' />
      )}
    </div>
  );
};

export default AppLogo;

AppLogo.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
