export const DEFAULT_FUNCTION = () => null;

export const TABLE_SIZE = {
  LARGE: 'large',
  MIDDLE: 'middle',
  SMALL: 'small',
};

export const DEFAULT_CONFIG_TABLE = {
  tableSize: TABLE_SIZE.MIDDLE,
  page: 1,
  pageSize: 10,
  sort: [{ field: 'created_at', desc: true }],
  keyword: '',
  timeRange: [null, null],
  filter: {},
};

export const KEY_EMPTY_SELECT = 'empty__$$__';
