import { getToken, removeToken } from './Application/AuthenStorage';
import axios from 'axios';
import antMessage from '../component/AntMessage/index';
const CORE_API = process.env.REACT_APP_CORE_API;

const addInterceptor = (instant) => {
  instant.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (config?.headers && !config?.headers?.Authorization) {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          config.headers.Authorization = '';
        }
      }

      return config;
    },
    (err) => Promise.reject(err),
  );

  instant.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status, data } = error.response || {};
      switch (status) {
        case 401:
          if (error?.config?.url?.includes('authenticate')) {
            return Promise.reject(error);
          }
          removeToken();
          antMessage.error('Phiên đăng nhập hết hạn');
          window.location.href = '/signin';
          break;
        case 403:
          // error({ statusCode: 403 });
          break;
        case 429:
          console.log('manyAction');
          break;
        case 404:
          if (data?.message) {
            console.error(data?.message);
          } else {
            console.log('NotFound');
          }
          break;
        case 400:
        case 432:
          if (data?.message) {
            console.error(data?.message);
          } else {
            console.log('showInternalServerError');
          }
          break;
        case 422:
        case 1:
          break;
        default:
          console.log('Failed');
          break;
      }
      return Promise.reject(error);
    },
  );
};

function createInstance(api) {
  const instant = axios.create({
    baseURL: api,
  });

  addInterceptor(instant);

  return instant;
}

export const instanceCoreApi = createInstance(CORE_API);

export default function setupAxiosDefault() {
  axios.defaults.baseURL = CORE_API;
  addInterceptor(axios);
}
