import React from 'react';
import '../AppLogo/index.style.less';
import PropTypes from 'prop-types';
import { useSidebarContext } from '../../../../utility/AppContextProvider/SidebarContextProvider';

const AppLogoWithoutText = ({ hasSidebarColor }) => {
  const { sidebarColorSet } = useSidebarContext();
  return (
    <div className='app-logo'>
      {hasSidebarColor && sidebarColorSet.mode === 'dark' ? (
        <img src='/assets/images/logo-icon-large.png' alt='crema-logo' />
      ) : (
        <img src='/assets/images/logo-icon-large.png' alt='crema-logo' />
      )}
    </div>
  );
};

export default AppLogoWithoutText;

AppLogoWithoutText.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
