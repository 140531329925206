import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useDispatch } from 'react-redux';
import { withResizeDetector } from 'react-resize-detector';
import { RESIZE_APP_CONTENT } from 'src/shared/constants/ActionTypes';
import { CLASS_NAME_LAYOUT } from 'src/shared/constants/Key';
import { AppSuspense } from '../../index';
import {
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from 'src/pages';
import AppErrorBoundary from '../AppErrorBoundary';
import './index.style.less';
import generateRoutes from '../../utility/RouteGenerator';
import { useAuthMethod, useAuthUser } from '../../utility/AuthHooks';
import { Navigate, Route, Routes } from 'react-router-dom';
// import { initialUrl } from '../../../shared/constants/AppConst';
import { getRoutePermissions } from 'src/@crema/utility/VerticalMenuUtils';
import antMessage from 'src/@crema/component/AntMessage/index';

const { Content } = Layout;

// eslint-disable-next-line react/prop-types
const AppContentView = ({ width, height }) => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useAuthUser();
  const { logout } = useAuthMethod();
  const initialUrl =
    getRoutePermissions(user?.permissions)[0]?.path ||
    getRoutePermissions(user?.permissions)[0]?.children[0]?.path ||
    '/signin';

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch({ type: RESIZE_APP_CONTENT, payload: { width, height } });
    }, 150);

    return () => {
      clearTimeout(timeout);
    };
  }, [width, height]);

  useEffect(() => {
    const filteredRoutes = authorizedStructure?.routes.filter((route) => {
      return user?.role?.includes(route.permittedRole);
    });
    if (filteredRoutes?.length == 0 && isAuthenticated) {
      antMessage.warning('Bạn không có quyền truy cập!');
      logout();
    }
  }, [user]);

  const routesGenerated = generateRoutes(
    {
      isAuthenticated: isAuthenticated,
      userRole: user?.role,
      unAuthorizedStructure,
      authorizedStructure,
      anonymousStructure,
    },
    user?.permissions,
  );

  return (
    <div className={'main-content-view-wrapper'}>
      <Content className={CLASS_NAME_LAYOUT}>
        <AppSuspense>
          <AppErrorBoundary>
            {routesGenerated}
            <Routes>
              <Route path='/' element={<Navigate to={initialUrl} replace />} />
            </Routes>
          </AppErrorBoundary>
        </AppSuspense>
      </Content>
    </div>
  );
};

export default withResizeDetector(AppContentView);
