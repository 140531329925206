import React from 'react';

import IntlMessages from '../../utility/IntlMessages';
import './index.style.less';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
const AppQuestion = () => {
  return (
    <div>
      <a className='question-link' onClick={(e) => e.preventDefault()}>
        <span className='question-icon'>
          <AiOutlineQuestionCircle />
        </span>
        <span className='question-text'>
          <IntlMessages id='common.question' />
        </span>
      </a>
    </div>
  );
};

export default AppQuestion;
