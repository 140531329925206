const API = {
  AUTH_ME: '/api/v1/user/me',
  FILE_UPLOAD: '/api/v1/upload/file',
  SIGN_IN: '/api/v1/authenticate',
  GET_ALL_MANAGER: '/api/v1/admin/user/all-manager',
  UPDATE_DEPARTMENT: '/api/v1/admin/department/update',
  ADD_NEW_DEPARTMENT: '/api/v1/admin/department/create',
  DELETE_DEPARTMENT: '/api/v1/admin/department/delete',
  GET_ALL_DEPARTMENT: '/api/v1/admin/department/all',
  ADD_NEW_ACCOUNT: '/api/v1/admin/user/create',
  UPDATE_ACCOUNT: '/api/v1/admin/user/update',
  DELETE_ACCOUNT: '/api/v1/admin/user/delete',
  GET_COMMON_ROLES: '/api/v1/common/role',
  UPDATE_SOURCE: '/api/v1/monitor/update',
  ACTION_RESET_PASSWORD: '/api/v1/admin/user/reset-password',
  ADD_NEW_CATEGORY: '/api/v1/category/create',
  UPDATE_CATEGORY: '/api/v1/category/update',
  DELETE_CATEGORY: '/api/v1/category/delete',
  ADD_NEW_KEYWORD: '/api/v1/manage/keyword-data/create',
  UPDATE_KEYWORD: '/api/v1/manage/keyword-data/update',
  DELETE_KEYWORD: '/api/v1/manage/keyword-data/delete',
  ADD_NEW_SOURCE: '/api/v1/monitor/create',
  DELETE_SOURCE: '',
  CRAW_FACEBOOK_INFO: '/api/v1/crawler/social/facebook',
  CRAW_YOUTUBE_CHANNEL_INFO: '/api/v1/crawler/social/youtube',
  CRAW_NEWS: '/api/v1/crawler/news',
  REQUEST_FORGOT_PASSWORD: '/api/v1/forgot-password',
  UPLOAD_LIST_BOTS: '/api/v1/admin/bot/import-file',
  ADD_NEW_PROXY: '/api/v1/admin/proxy-info/import-file',
  DELETE_PROXY: '/api/v1/admin/proxy-info/delete',
  BOT_LOG_HISTORY: '/api/v1/admin/bot-log-history',
  ADD_COMMAND_MANAGEMENT: '/api/v1/admin/command-management/create-one',
  DELETE_COMMAND_MANAGEMENT: '/api/v1/admin/command-management/delete',

  SEARCH_HISTORY_LOGIN: '/api/v1/admin/user/user-action-log/search',

  SEARCH_LIST_SOURCE: '/api/v1/project-group/search',
};

export default API;
