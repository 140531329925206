import PropTypes from 'prop-types';
import React from 'react';
import './page_breadcrumb.styles.less';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

const SubHeaderAppTemplate = ({
  title,
  subTitle,
  children,
  rightTitleContent,
  isTabChildren,
  isFixed,
  onBackToPreviousPage,
}) => {
  const navigate = useNavigate();
  const { messages } = useIntl();

  const backToPreviousPage = () => {
    if (onBackToPreviousPage) {
      return onBackToPreviousPage();
    }
    navigate(-1);
  };

  const titleShow = messages[title] || title;

  return (
    <div
      className={`page_breadcrumb-container ${
        subTitle || rightTitleContent
          ? 'page_breadcrumb-padding'
          : 'page_breadcrumb-padding_only-title'
      } ${isTabChildren && 'page_breadcrumb-padding-tabs'} 
        ${isFixed && 'page_breadcrumb-padding-fixed'}
      `}>
      {subTitle && <div>{subTitle}</div>}
      <div className='page_breadcrumb__page-row-title'>
        <div className='page_breadcrumb__page-title_container'>
          {subTitle && (
            <div
              className='page_breadcrumb__page-icon'
              onClick={backToPreviousPage}>
              <AiOutlineArrowLeft />
            </div>
          )}
          <h1 className='page_breadcrumb__page-title'>{titleShow}</h1>
        </div>
        <div>{rightTitleContent}</div>
      </div>
      {children}
    </div>
  );
};

SubHeaderAppTemplate.propTypes = {
  title: PropTypes.any,
  subTitle: PropTypes.any,
  children: PropTypes.any,
  rightTitleContent: PropTypes.any,
  isTabChildren: PropTypes.bool,
  onBackToPreviousPage: PropTypes.func,
  isFixed: PropTypes.bool,
};

SubHeaderAppTemplate.defaultProps = {
  children: null,
};

export default SubHeaderAppTemplate;
